<template>
  <div class="proDetails w">
    <div class="pro_info_top_box">
      <div class="pro_image">
        <img :src="detailsData.images_view[0]" alt="">
      </div>
      <div class="pro_info_box">
        <div class="pro_name">
          {{ detailsData.name }}
        </div>
        <div class="pro_price">
          <span class="p_desc_info_price" v-if="detailsData.price_type == 2">面议</span>
          <span class="p_desc_info_price" v-else>{{ detailsData.price }}</span>
        </div>
        <div class="pro_row_box">
          <div class="pro_row_title">产品类型:</div>
          <div class="pro_row_text">{{ detailsData.category_name }}</div>
        </div>
        <div class="pro_row_box">
          <div class="pro_row_title">是否含税:</div>
          <div class="pro_row_text">{{ pro_tax_type[detailsData.if_tax] }}</div>
        </div>
        <div class="pro_row_box">
          <div class="pro_row_title">供应地址:</div>
          <div class="pro_row_text">{{ detailsData.area }}</div>
        </div>
        <div class="company_box">
          <div class="company_info_box">
            <div class="company_img_box">
              <img :src="detailsData.image" alt="">
            </div>
            <div class="company_bottom_info">
              <div class="company_name">
                {{ detailsData.company_name}}
              </div>
              <div class="company_num_pro">
                共计{{ detailsData.company_product_num }}件产品
              </div>
            </div>
          </div>
          <div class="check_company_btn">
            <button class="s_blue_btn" @click="goEpDeatils">查看企业</button>
          </div>
        </div>
        <div class="pro_op_info_box">
          <button class="blue_btn" @click="goLink('/personalCenter/member')" v-if="!$store.state.userInfo.vip.vip_level">开通会员查看电话</button>
          <button class="blue_btn" @click="callPhone" v-if="$store.state.userInfo.vip.vip_level&&$store.state.userInfo.vip.vip_level!=0">电话联系</button>
          <div class="collect_btn" @click="collectFunc">
            <img src="../../assets/img/icon/icon_active_collect.png" alt="" v-if="detailsData.if_collect">
            <img src="../../assets/img/icon/icon_collect.png" alt="" v-else>
            <span>收藏</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情盒子 -->
    <div class="area_box">
      <div class="area-title">产品详情</div>
      <div class="inner_desc_box" v-if="detailsData.desc">
        <p v-html="formattedString"></p>
      </div>
      <div class="inner_desc_box" v-else>
        ****************************************************************************
      </div>

      <div class="files_item" v-for="(item,index) in detailsData.images_view" :key="index">
        <img :src="item" />
      </div>
      <bigOpenVipVue v-if="auth!=='pass'"></bigOpenVipVue>
    </div>
  </div>
</template>

<script>
import bigOpenVipVue from '../../components/vipPopup/bigOpenVip.vue';
import smallOpenVip from '../../components/vipPopup/smallOpenVip.vue';
export default {
  components: {
    smallOpenVip,
    bigOpenVipVue
  },
  data () {
    return {
      vipShow: false,
      approveShow: false,
      id: '',
      auth: '',
      pageAuth: 'package_phone',
      detailsData: {}
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    closeVipShow () {
      this.vipShow = false;
    },
    closeApproveShow () {
      this.approveShow = false;
    },
    collectFunc () {
      this.$api.collect({ id: this.id, type: 'product' }).then(res => {
        if (res.code == 1) {
          if (res.data.if_collect == 0) {
            this.$message.success('已取消收藏');
          } else if (res.data.if_collect == 1) {
            this.$message.success('收藏成功!');
          }
          this.detailsData.if_collect = res.data.if_collect;
        }
      });
    },
    getDetails () {
      this.$api.getProductDetail({ id: this.id }).then(res => {
        this.detailsData = res.data;
        this.auth = 'pass';
      });
    },
    //前往企业详情页
    goEpDeatils () {
      this.$router.push({
        path: '/ep-details',
        query: {
          id: this.detailsData.company_id,
        }
      })
    },
    callPhone () {
      this.$api.getProductPhone({ id: this.id }).then(res => {
        if (res.code == 1) {
          if (res.data.contact_phone) {
            alert(`
                联系方式:${res.data.contact_phone}
          `)
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    formattedString () {
      // 使用正则表达式将换行符替换为<br>标签
      return this.detailsData.desc.replace(/\r\n/g, "<br>");
    },
  },
};
</script>

<style lang="scss">
.proDetails {
  min-height: 100vh;
  background-color: #f4f6fa;
  box-sizing: border-box;
  padding: 31px 0px;
  .pro_info_top_box {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 28px 31px 44px;
    display: flex;
    margin-bottom: 13px;
    .pro_image {
      width: 465px;
      height: 465px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      flex: none;
      margin-right: 36px;
    }
    .pro_info_box {
      padding-top: 34px;
      .pro_name {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 22px;
      }
      .pro_price {
        font-size: 18px;
        font-weight: 500;
        color: #ff5100;
        margin-bottom: 21px;
        .p_desc_info_price {
        }
      }
      .pro_row_box {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .pro_row_title {
          margin-right: 17px;
        }
        .pro_row_text {
        }
      }
      .company_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 357px;
        padding: 14px 17px 18px;
        box-sizing: border-box;
        background-color: #e6f1fd;
        border-radius: 8px;
        margin-bottom: 66px;
        .company_info_box {
          display: flex;
          align-items: center;
          .company_img_box {
            width: 38px;
            height: 38px;
            background: #ffffff;
            border-radius: 50%;
            overflow: hidden;
            opacity: 1;
            margin-right: 8px;
          }
          .company_bottom_info {
            .company_name {
              font-size: 14px;
              color: #333;
              margin-bottom: 4px;
            }
            .company_num_pro {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .check_company_btn {
          .s_blue_btn {
            padding: 7px 10px;
          }
        }
      }
      .pro_op_info_box {
        display: flex;
        .blue_btn {
          width: 357px;
          padding: 0;
          display: flex;
          align-items: center;
          height: 50px;
          font-size: 15px;
          justify-content: center;
          box-sizing: border-box;
          border-radius: 8px;
        }
        .collect_btn {
          display: flex;
          align-items: center;
          margin-left: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          width: fit-content;
          float: right;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
            margin-right: 3px;
          }
        }
      }
    }
  }
  .inner_desc_box {
    margin-bottom: 30px;
  }
  .files_list_box {
    .files_item {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
